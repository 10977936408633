
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCurrentUser, setCurrentUser} from "@/utils/index"
import {companyPolicyAgree}  from "@/api/companypolicy";
import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue"

export default defineComponent({
  name: "companypolicymain",
  components: {
    CompanyPolicy
  },
  data() {
    return {
      accountlist: [],
      currentUsid: "",
      proceedBtnDisabled : true
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Company Policy");
    });
  }, 
  methods: {
      async checkBoxValidator(event){
        if (event.target.checked) {
          this.proceedBtnDisabled = false;
        } else {
          this.proceedBtnDisabled = true;
        }
      },
      async agreeTocompanyPolicy(){
        const response = await companyPolicyAgree();

        if(response != null){
          if(localStorage.getItem("pt") === 'ovi'){
            this.$router.push('/dashboard/reminderspage') ;
          } else if(localStorage.getItem("pt") === 'ecp' || localStorage.getItem("pt") === 'edp'){
            this.$router.push('/dashboardecp/reminderspage') ;
          }
            return; 
        } 
      }
  },
  mounted() {
    
      let user = getCurrentUser();
      this.currentUsid = user.usid;
      
  }
});
